import {getSupportedLanguagesRegex} from 'src/Locale/getSupportedLanguages';

export const langPrefix = `:language(${getSupportedLanguagesRegex()})`;

const EnumRoutes = {
	DEFAULT: '/',
	HOME: `/${langPrefix}/`,
	CASES: `/${langPrefix}/cases/`,
	CASE: `/${langPrefix}/case/:caseId/`,
	JOBS: `/${langPrefix}/jobs/`,
	JOB: `/${langPrefix}/job/:jobId/`,
	CONTACTS: `/${langPrefix}/contacts`,
	PDP: `/${langPrefix}/personal-data-policy`,
	404: `/error/page-not-found`,
};

export default EnumRoutes;
