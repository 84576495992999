import {Theme, createStyles} from '@material-ui/core';

export const cookieNoticeStyles = (theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.common.white,
			flexDirection: 'row',
			flexWrap: 'nowrap',

			'& .MuiSnackbarContent-message': {
				display: 'flex',
				flexDirection: 'row',
				color: theme.palette.primary.main,
				alignItems: 'center',
				marginRight: theme.spacing(3),
			},
		},
		cookie: {
			fontSize: 30,
			paddingRight: theme.spacing(1),
		},
		more: {
			marginRight: theme.spacing(1),
		},
	});
