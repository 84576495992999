import EnumLanguage from 'src/Locale/EnumLanguage';
import {langPrefix} from 'src/Routes/EnumRoutes';

export const getRoutePathname = (route: string, params: IHaveLanguage) => {
	return route.replace(langPrefix, params.language);
};

interface IHaveLanguage {
	language: EnumLanguage | string;
}
