import CSS from 'csstype';

import GolosTextWebBlackEot from './Golos Text_Black.eot';
import GolosTextWebBlackTtf from './Golos Text_Black.ttf';
import GolosTextWebBlackWoff from './Golos Text_Black.woff';
import GolosTextWebBlackWoff2 from './Golos Text_Black.woff2';

export const GolosTextBlack: CSS.AtRule.FontFace = {
	fontFamily: 'GolosTextWebBlack',
	src: `
		url('${GolosTextWebBlackEot}'),
		url('${GolosTextWebBlackEot}?#iefix') format('embedded-opentype'),
		url('${GolosTextWebBlackWoff2}') format('woff2'),
		url('${GolosTextWebBlackWoff}') format('woff'),
		url('${GolosTextWebBlackTtf}') format('truetype');
	`,
	fontWeight: 'normal',
	fontStyle: 'normal',
	fontDisplay: 'swap',
};
