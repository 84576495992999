import i18n, {Resource} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import values from 'lodash/values';
import {initReactI18next} from 'react-i18next';

import EnumLanguage from 'src/Locale/EnumLanguage';
import EnumTranslationNamespace from 'src/Locale/EnumTranslationNamespace';
import {getSupportedLanguages} from 'src/Locale/getSupportedLanguages';

const languages = getSupportedLanguages();
const resources: Resource = {};
const defaultNameSpaces = values(EnumTranslationNamespace);

for (let i = 0; i < languages.length; i++) {
	const langCode = languages[i];
	resources[langCode] = {};

	for (let j = 0; j < defaultNameSpaces.length; j++) {
		const ns = defaultNameSpaces[j];
		resources[langCode][ns] = require(`src/Locale/Translations/${langCode}/${ns}.json`);
	}
}

i18n
	.use(intervalPlural)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		fallbackLng: EnumLanguage.RU,
		debug: false,
		keySeparator: ':',
		supportedLngs: languages,
		ns: defaultNameSpaces,
		defaultNS: 'common',
		saveMissing: true,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		detection: {
			order: ['path'],
		},
	});

export default i18n;
