import attempt from 'lodash/attempt';
import get from 'lodash/get';
import isError from 'lodash/isError';

const currentEnv = process.env;

export default class EnvHelper {
	static getLinkedInPageUrl() {
		return get(currentEnv, 'REACT_APP_LINKED_IN_URL', '');
	}

	static getXingPageUrl() {
		return get(currentEnv, 'REACT_APP_XING_URL', '');
	}

	static shouldEnableYandexMetrika(): boolean {
		return get(currentEnv, 'REACT_APP_METRIKA_ENABLED', 'false') === 'true';
	}

	static getYandexMetrikaCode(): number {
		return parseInt(get(currentEnv, 'REACT_APP_METRIKA_CODE', ''), 10);
	}

	static shouldShowTeamBlock() {
		const value = attempt(JSON.parse, get(currentEnv, 'REACT_APP_BLOCK_TEAM_SHOW', 'false'));

		return !isError(value) ? value : false;
	}

	static getAporiaEmail() {
		return get(currentEnv, 'REACT_APP_APORIA_EMAIL', 'hello@aporia.su');
	}

	static getJobApplicationsEmail() {
		return get(currentEnv, 'REACT_APP_APORIA_JOBS_EMAIL', 'hello@aporia.su');
	}
}
