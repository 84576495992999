import React, {Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import CookieNotice from 'src/Components/Common/CookieNotice/CookieNotice';
import PageLoadSpinner from 'src/Components/Common/PageLoadSpinner';
import EnumRoutes from 'src/Routes/EnumRoutes';

const MainPage = React.lazy(() => import('src/Routes/Pages/MainPage'));
const CasesListingPage = React.lazy(() => import('src/Routes/Pages/CasesListingPage'));
const JobsListingPage = React.lazy(() => import('src/Routes/Pages/JobsListingPage'));
const JobPage = React.lazy(() => import('src/Routes/Pages/JobPage'));
const RedirectToLocalizedPage = React.lazy(() => import('src/Routes/Pages/RedirectToLocalizedPage'));
const PageNotFound = React.lazy(() => import('src/Routes/Pages/PageNotFound/PageNotFound'));
const Contacts = React.lazy(() => import('src/Routes/Pages/Contacts'));
const SingleCasePage = React.lazy(() => import('src/Routes/Pages/SingleCasePage'));
const PersonalDataPolicy = React.lazy(() => import('src/Routes/Pages/PersonalDataPolicy/PersonalDataPolicy'));

const RouterContainer = () => (
	<BrowserRouter basename={process.env.PUBLIC_URL}>
		<CookieNotice />
		<Suspense fallback={<PageLoadSpinner />}>
			<Switch>
				<Route path={EnumRoutes.HOME} exact={true} component={MainPage} />
				<Route path={EnumRoutes.CASES} exact={true} component={CasesListingPage} />
				<Route path={EnumRoutes.CASE} component={SingleCasePage} />
				<Route path={EnumRoutes.CONTACTS} exact={true} component={Contacts} />
				<Route path={EnumRoutes.JOBS} exact={true} component={JobsListingPage} />
				<Route path={EnumRoutes.JOB} component={JobPage} />
				<Route path={EnumRoutes.PDP} exact={true} component={PersonalDataPolicy} />
				<Route path={EnumRoutes.DEFAULT} exact={true} component={RedirectToLocalizedPage} />
				<Route component={PageNotFound} />
			</Switch>
		</Suspense>
	</BrowserRouter>
);

export default RouterContainer;
