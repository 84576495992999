enum EnumTranslationNamespace {
	COMMON = 'common',
	NAVIGATION = 'navigation',
	SHOWCASE = 'showcase',
	ERROR = 'error',
	TECHNOLOGY = 'technology',
	EXPECTATIONS = 'expectations',
	TEAM = 'team',
	STARTUP = 'startup',
	INTERACTIONS = 'interactions',
	CONTACTUS = 'contactus',
	BUSINESS = 'business',
	MODAL = 'modal',
	PERFORMANCE = 'performance',
	PDP = 'pdp',
	JOB = 'job',
	BROADTECH = 'broadtech',
	PARADOX = 'paradox',
	META = 'meta',
	CASES = 'cases',
}

export default EnumTranslationNamespace;
