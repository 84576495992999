import {Theme, createStyles} from '@material-ui/core';
import {WithStyles, withStyles} from '@material-ui/core/styles';

import StaticLogo from 'src/Assets/Images/static-logo.png';

const styles = (_theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	});

const PageLoadSpinner = (props: WithStyles) => (
	<section className={props.classes.root}>
		<img src={StaticLogo} alt="Loading" />
	</section>
);

export default withStyles(styles)(PageLoadSpinner);
