import {Button, Hidden, Slide, Snackbar, SnackbarContent} from '@material-ui/core';
import {WithStyles, withStyles} from '@material-ui/core/styles';
import {TransitionProps} from '@material-ui/core/transitions';
import {useState} from 'react';
import {Trans, WithTranslation, withTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';
import store from 'store2';

import {cookieNoticeStyles} from 'src/Components/Common/CookieNotice/cookieNoticeStyles';
import EnumRoutes from 'src/Routes/EnumRoutes';
import {getRoutePathname} from 'src/Routes/getRoutePathname';

const STORE_COOKIES_KEY = 'cookies';

const CookieNotice = (props: WithStyles & WithTranslation) => {
	const [shouldShow, setShouldShow] = useState(!store.has(STORE_COOKIES_KEY));

	const onAccept = () => {
		setShouldShow(false);
		store.set(STORE_COOKIES_KEY, true);
	};

	return (
		<Snackbar
			open={shouldShow}
			TransitionComponent={(slideProps: TransitionProps) => <Slide {...slideProps} direction="up" />}
			key="cookies"
		>
			<SnackbarContent
				action={[
					<Hidden smDown key="more">
						<Button
							color="primary"
							disableElevation
							className={props.classes.more}
							component={RouterLink}
							to={getRoutePathname(EnumRoutes.PDP, {language: props.i18n.language})}
						>
							{props.t('more')}
						</Button>
					</Hidden>,
					<Button color="primary" variant="contained" disableElevation key="ok" onClick={onAccept}>
						{props.t('ok')}
					</Button>,
				]}
				className={props.classes.root}
				message={
					<Trans
						i18nKey="cookie-text"
						ns="common"
						components={[<span key={1} className={props.classes.cookie} />, <span key={2} />]}
					/>
				}
			/>
		</Snackbar>
	);
};

export default withStyles(cookieNoticeStyles)(withTranslation('common')(CookieNotice));
