import {createTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {TypographyStyleOptions} from '@material-ui/core/styles/createTypography';
import {Shadows} from '@material-ui/core/styles/shadows';

import {GolosTextBlack} from 'src/Assets/Fonts/Golos Text_Black/GolosTextBlack';
import {UbuntuRegular} from 'src/Assets/Fonts/Ubuntu_Regular/UbuntuRegular';

const defaultTitleStyles: TypographyStyleOptions = {
	textTransform: 'uppercase',
	fontFamily: 'GolosTextWebBlack',
	whiteSpace: 'pre-line',
};

const shadowColor = '50, 21, 109';
const shadows: Shadows = [
	'none',
	`0px 2px 1px -1px rgba(${shadowColor}, 0.2),0px 1px 1px 0px rgba(${shadowColor},0.14),0px 1px 3px 0px rgba(${shadowColor}, 0.12)`,
	`0px 3px 1px -2px rgba(${shadowColor},0.2),0px 2px 2px 0px rgba(${shadowColor},0.14),0px 1px 5px 0px rgba(${shadowColor},0.12)`,
	`0px 3px 3px -2px rgba(${shadowColor},0.2),0px 3px 4px 0px rgba(${shadowColor},0.14),0px 1px 8px 0px rgba(${shadowColor},0.12)`,
	`0px 2px 4px -1px rgba(${shadowColor},0.2),0px 4px 5px 0px rgba(${shadowColor},0.14),0px 1px 10px 0px rgba(${shadowColor},0.12)`,
	`0px 3px 5px -1px rgba(${shadowColor},0.2),0px 5px 8px 0px rgba(${shadowColor},0.14),0px 1px 14px 0px rgba(${shadowColor},0.12)`,
	`0px 3px 5px -1px rgba(${shadowColor},0.2),0px 6px 10px 0px rgba(${shadowColor},0.14),0px 1px 18px 0px rgba(${shadowColor},0.12)`,
	`0px 4px 5px -2px rgba(${shadowColor},0.2),0px 7px 10px 1px rgba(${shadowColor},0.14),0px 2px 16px 1px rgba(${shadowColor},0.12)`,
	`0px 5px 5px -3px rgba(${shadowColor},0.2),0px 8px 10px 1px rgba(${shadowColor},0.14),0px 3px 14px 2px rgba(${shadowColor},0.12)`,
	`0px 5px 6px -3px rgba(${shadowColor},0.2),0px 9px 12px 1px rgba(${shadowColor},0.14),0px 3px 16px 2px rgba(${shadowColor},0.12)`,
	`0px 6px 6px -3px rgba(${shadowColor},0.2),0px 10px 14px 1px rgba(${shadowColor},0.14),0px 4px 18px 3px rgba(${shadowColor},0.12)`,
	`0px 6px 7px -4px rgba(${shadowColor},0.2),0px 11px 15px 1px rgba(${shadowColor},0.14),0px 4px 20px 3px rgba(${shadowColor},0.12)`,
	`0px 7px 8px -4px rgba(${shadowColor},0.2),0px 12px 17px 2px rgba(${shadowColor},0.14),0px 5px 22px 4px rgba(${shadowColor},0.12)`,
	`0px 7px 8px -4px rgba(${shadowColor},0.2),0px 13px 19px 2px rgba(${shadowColor},0.14),0px 5px 24px 4px rgba(${shadowColor},0.12)`,
	`0px 7px 9px -4px rgba(${shadowColor},0.2),0px 14px 21px 2px rgba(${shadowColor},0.14),0px 5px 26px 4px rgba(${shadowColor},0.12)`,
	`0px 8px 9px -5px rgba(${shadowColor},0.2),0px 15px 22px 2px rgba(${shadowColor},0.14),0px 6px 28px 5px rgba(${shadowColor},0.12)`,
	`0px 8px 10px -5px rgba(${shadowColor},0.2),0px 16px 24px 2px rgba(${shadowColor},0.14),0px 6px 30px 5px rgba(${shadowColor},0.12)`,
	`0px 8px 11px -5px rgba(${shadowColor},0.2),0px 17px 26px 2px rgba(${shadowColor},0.14),0px 6px 32px 5px rgba(${shadowColor},0.12)`,
	`0px 9px 11px -5px rgba(${shadowColor},0.2),0px 18px 28px 2px rgba(${shadowColor},0.14),0px 7px 34px 6px rgba(${shadowColor},0.12)`,
	`0px 9px 12px -6px rgba(${shadowColor},0.2),0px 19px 29px 2px rgba(${shadowColor},0.14),0px 7px 36px 6px rgba(${shadowColor},0.12)`,
	`0px 10px 13px -6px rgba(${shadowColor},0.2),0px 20px 31px 3px rgba(${shadowColor},0.14),0px 8px 38px 7px rgba(${shadowColor},0.12)`,
	`0px 10px 13px -6px rgba(${shadowColor},0.2),0px 21px 33px 3px rgba(${shadowColor},0.14),0px 8px 40px 7px rgba(${shadowColor},0.12)`,
	`0px 10px 14px -6px rgba(${shadowColor},0.2),0px 22px 35px 3px rgba(${shadowColor},0.14),0px 8px 42px 7px rgba(${shadowColor},0.12)`,
	`0px 11px 14px -7px rgba(${shadowColor},0.2),0px 23px 36px 3px rgba(${shadowColor},0.14),0px 9px 44px 8px rgba(${shadowColor},0.12)`,
	`0px 11px 15px -7px rgba(${shadowColor},0.2),0px 24px 38px 3px rgba(${shadowColor},0.14),0px 9px 46px 8px rgba(${shadowColor},0.12)`,
];

const secondaryLight = '#FFE340';
const secondaryDark = '#FFC700';
const primaryMain = '#5037A7';
const lightPurple = '#DFDFDF';

let aporiaTheme = createTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [UbuntuRegular, GolosTextBlack],
			},
		},
		MuiLink: {
			root: {
				padding: '0 3px',
				transition: 'borderBottomColor',
				transitionDuration: '300ms',
			},
			underlineAlways: {
				textDecoration: 'none',
				borderBottomWidth: 1,
				borderBottomStyle: 'solid',
				borderBottomColor: lightPurple,

				'&:hover': {
					textDecoration: 'none',
					borderBottomColor: primaryMain,
				},
			},
			underlineHover: {
				textDecoration: 'none',
				borderBottomWidth: 1,
				borderBottomStyle: 'solid',
				borderBottomColor: lightPurple,

				'&:hover': {
					textDecoration: 'none',
					borderBottomColor: primaryMain,
				},
			},
		},
		MuiTextField: {
			root: {
				borderRadius: '6px',
			},
		},
		MuiButton: {
			root: {
				borderRadius: '6px',
			},
			containedSecondary: {
				fontWeight: 900,

				'&:hover': {
					backgroundColor: secondaryLight,
				},
				'&:active': {
					backgroundColor: secondaryDark,
				},
			},
			containedSizeLarge: {
				fontFamily: 'GolosTextWebBlack',
				paddingLeft: 32,
				paddingRight: 32,
				paddingTop: 16,
				paddingBottom: 16,
			},
		},
	},
	breakpoints: {
		values: {
			xs: 0, //default value by material ui
			sm: 600, //default value by material ui
			md: 960, //default value by material ui
			lg: 1280, //default value by material ui
			xl: 1920, //default value by material ui
			xs320: 320,
			sm374: 374,
			sm400: 400,
			sm499: 499,
			sm570: 570,
			md750: 750,
		},
	},
	palette: {
		background: {
			default: '#fff',
			lightGrey: '#F4F4F9',
			mainGradient: 'linear-gradient(270deg, #3F32D4 0%, #6932D3 100%)',
		},
		primary: {
			main: '#5037A7',
			light: '#9479f2',
			dark: '#7159C6',
		},
		secondary: {
			main: '#FFDA00',
			light: secondaryLight,
			dark: secondaryDark,
		},
		common: {
			black: '#220740',
			white: '#F8F7FA',
		},
		text: {
			primary: '#3C1962',
			secondary: '#220740',
			light: '#AB96F2',
			lightDark: '#371977',
			grey: '#585858',
			lightGrey: '#6B737E',
			line: '#7EABF0',
			headerLink: '#BCE1FE',
			headerButton: '#7A52ED',
			// footerLink: '#A998E5',
			paper: '#474747',
			prdCaseFooterLink: '#A998E5',
			footerLink: '#313131',
			border: '#F0F1F3',
		},
		error: {
			light: '#EFB2BA',
			main: '#F33636',
		},
	},
	typography: {
		fontFamily: ['UbuntuRegular'].join(','),
		h1: defaultTitleStyles,
		h2: defaultTitleStyles,
		h3: defaultTitleStyles,
		h4: defaultTitleStyles,
		h5: defaultTitleStyles,
		h6: defaultTitleStyles,
	},
	shadows: shadows,
});
aporiaTheme = responsiveFontSizes(aporiaTheme);

export default aporiaTheme;
