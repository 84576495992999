import {CssBaseline, ThemeProvider} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import {WithTranslation, withTranslation} from 'react-i18next';
import {YMInitializer} from 'react-yandex-metrika';

import 'src/Locale/i18next';
import RouterContainer from 'src/Routes/RouterContainer';
import aporiaTheme from 'src/Theme/aporiaTheme';
import EnvHelper from 'src/Utils/EnvHelper';

const App = (props: WithTranslation) => (
	<ThemeProvider theme={aporiaTheme}>
		{EnvHelper.shouldEnableYandexMetrika() && (
			<YMInitializer
				accounts={[EnvHelper.getYandexMetrikaCode()]}
				options={{
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					webvisor: true,
					trackHash: true,
				}}
			/>
		)}
		<CssBaseline />
		<Helmet>
			<title>{props.t('meta.common.title')}</title>
			<meta name="description" content={props.t('meta.common.description')} />
			<meta name="keywords" content={props.t('meta.common.keywords')} />
		</Helmet>
		<RouterContainer />
	</ThemeProvider>
);

export default withTranslation('meta')(App);
