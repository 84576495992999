import * as CSS from 'csstype';

import UbuntuRegularEot from './ubuntu-regular.eot';
import UbuntuRegularSvg from './ubuntu-regular.svg';
import UbuntuRegularTtf from './ubuntu-regular.ttf';
import UbuntuRegularWoff from './ubuntu-regular.woff';
import UbuntuRegularWoff2 from './ubuntu-regular.woff2';

export const UbuntuRegular: CSS.AtRule.FontFace = {
	fontFamily: 'UbuntuRegular',
	src: `
		url('${UbuntuRegularEot}'),
		url('${UbuntuRegularEot}?#iefix') format('embedded-opentype'),
		url('${UbuntuRegularWoff2}') format('woff2'),
		url('${UbuntuRegularWoff}') format('woff'),
		url('${UbuntuRegularTtf}') format('truetype'),
		url('${UbuntuRegularSvg}#Ubuntu') format('svg');
	`,
	fontWeight: 'normal',
	fontStyle: 'normal',
	fontDisplay: 'swap',
};
